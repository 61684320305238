<template>
  <div class="user-details">
    <div v-if="item" class="px-4 mt-5">
      <v-row class="user-details-header">
        <v-col cols="12" md="12">
          <div class="text-center mx-auto">
            <img v-if="item.google" class="account-avatar" :src="item.google.picture" alt="" />
            <img v-else class="account-avatar" :src="avatar" alt="" />
          </div>
        </v-col>
        <v-col class="my-2" cols="12" md="12">
          <div>
            <v-btn
              color="secondary"
              elevation="0"
              block
              @click="newNotificationDialog = true"
            >
              <span class="white--text font-14">Send Notification</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="user-details-details">
        <v-col cols="12" md="3">
          <p class="font-500">Name:</p>
        </v-col>
        <v-col cols="12" md="9">
          <p>{{ item.name || "-" }}</p>
        </v-col>
        <v-col cols="12" md="3">
          <p class="font-500">Email:</p>
        </v-col>
        <v-col cols="12" md="9">
          <p>{{ item.email || "-" }}</p>
        </v-col>
        <v-col cols="12" md="3">
          <p class="font-500">Address:</p>
        </v-col>
        <v-col cols="12" md="9">
          <p>
            {{ item.address?.address || "-" }},
            {{ item.address?.province || "-" }},
            {{ item.address?.city || "-" }}
          </p>
        </v-col>
        <v-col cols="12" md="3">
          <p class="font-500">Phone:</p>
        </v-col>
        <v-col cols="12" md="9">
          <p>{{ item.phone || "-" }}</p>
        </v-col>
        <v-col v-if="item.phoneVerified" cols="12" md="3">
          <p class="font-500">Verified:</p>
        </v-col>
        <v-col v-if="item.phoneVerified" cols="12" md="9">
          <p>{{ item.phoneVerified.verified ? "Yes" : "No" }}</p>
        </v-col>
        <v-col v-if="item.phoneVerified" cols="12" md="3">
          <p class="font-500">Verification code:</p>
        </v-col>
        <v-col v-if="item.phoneVerified" cols="12" md="9">
          <p>{{ item.phoneVerified.code || "-" }}</p>
        </v-col>
        <v-col cols="12" md="3">
          <p class="font-500">Birthdate:</p>
        </v-col>
        <v-col cols="12" md="9">
          <p>{{ $moment(item.birthdate).format("LL") || "-" }}</p>
        </v-col>
        <v-col cols="12" md="3">
          <p class="font-500">Last logged in:</p>
        </v-col>
        <v-col cols="12" md="9">
          <p>{{ $moment(item.lastLogin).format("LLLL") || "-" }}</p>
        </v-col>
        <v-col cols="12" md="3">
          <p class="font-500">Registered:</p>
        </v-col>
        <v-col cols="12" md="9">
          <p>{{ $moment(item.created_at).format("LLLL") || "-" }}</p>
        </v-col>
        <v-col cols="12" md="3">
          <p class="font-500">Notifications Preferences</p>
        </v-col>
        <v-col cols="12" md="9">
          <p
            :class="
              item.notificationSettings &&
              item.notificationSettings.messageOn === true
                ? 'selected-notification-preference'
                : 'unselected-notification-preference'
            "
          >
            Receive messages
          </p>
          <p
            :class="
              item.notificationSettings &&
              item.notificationSettings.announcementOn === true
                ? 'selected-notification-preference'
                : 'unselected-notification-preference'
            "
          >
            Receive announcements
          </p>
          <p
            :class="
              item.notificationSettings &&
              item.notificationSettings.dayOn === true
                ? 'selected-notification-preference'
                : 'unselected-notification-preference'
            "
          >
            Receive day notifications
          </p>
          <p
            :class="
              item.notificationSettings &&
              item.notificationSettings.upcomingOn === true
                ? 'selected-notification-preference'
                : 'unselected-notification-preference'
            "
          >
            Receive upcoming notifications
          </p>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="newNotificationDialog" :width="!$vuetify.breakpoint.mobile ? '50%': '100%'">
      <new-notification
        :key="newNotificationDialog"
        @close="newNotificationDialog = false"
        :user="item"
      ></new-notification>
    </v-dialog>
  </div>
</template>

<script>
import NewNotification from "@/components/dialogs/NewNotification.vue";
export default {
  components: {
    NewNotification
  },
  props: {
    item: {
      type: [Object],
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    avatar: require("@/assets/images/global/avatar.png"),
    newNotificationDialog: false
  }),
  methods: {}
};
</script>

<style>
.user-details {
  .col-12 {
    padding: 0;
  }
  .account-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .selected-notification-preference {
    color: green;
    padding: 0;
    margin: 0;
  }
  .unselected-notification-preference {
    color: red;
    text-decoration: line-through;
    padding: 0;
    margin: 0;
  }
  .user-details-header {
    height: 200px;
  }
}
</style>
